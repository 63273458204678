import Axios from '@/axios'

// 查看绩效核算内部生产资金比例
export const productionCostRatioLogByBusiness = function (cond = {}) {
  return Axios.post('/productionCostRatioLog/listByBusiness', cond)
}

// 查看绩效核算外协费用数组
export const projectOutsourceLogByBusiness = function (cond = {}) {
  return Axios.post('/projectOutsourceLog/listByBusiness', cond)
}

// 查看项目绩效分配方案内部生产资金比例
export const productionCostRatioLogByProject = function (cond = {}) {
  return Axios.post('/productionCostRatioLog/listByProject', cond)
}

// 查看项目绩效分配方案外协生产资金数组
export const projectOutsourceLogByProject = function (cond = {}) {
  return Axios.post('/projectOutsourceLog/listByProject', cond)
}
